import React, { FC } from 'react';
import { Header } from './components/Header';
import { IconName } from '../tailwind/Icon';

export interface NavigationLink {
  label: string;
  href: string;
  icon: IconName;
}

const NAVIGATION_LINKS: NavigationLink[] = [
  {
    label: 'Home',
    href: '/',
    icon: 'Home',
  },
  {
    label: 'Popular',
    href: '/popular',
    icon: 'Fire',
  },
  {
    label: 'Users',
    href: '/users',
    icon: 'UserGroup',
  },
  // {
  //   label: 'Trending',
  //   href: '/#trending',
  //   icon: 'TrendingUp',
  // },
];

export const LayoutSimple: FC = ({ children }) => {
  return (
    <div className="flex flex-col flex-auto bg-gray-100">
      <Header showSearch={false} links={NAVIGATION_LINKS} />
      <main className="h-full">{children}</main>
    </div>
  );
};
