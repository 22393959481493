import React, { FC, useState } from 'react';
import Router from 'next/router';
import Link from 'next/link';
import { useApolloClient } from '@apollo/react-hooks';
import { get } from 'lodash';
import { Button } from '@pixby/ui';
import { Typography } from '../components/tailwind/Typography';
import { Input } from '../components/tailwind/Input';
import { LayoutSimple } from '../components/layouts/LayoutSimple';
import { useLoginMutation } from '../generated/graphql';
import { withUser } from '../hocs/with-user';

export const Login: FC = ({}) => {
  const apolloClient = useApolloClient();
  const [formValues, setFormValues] = useState({
    username: '',
    email: '',
    password: '',
  });
  const [loginMutation] = useLoginMutation();
  const handleChange = e => {
    const newValues = { ...formValues };
    newValues[e.target.name] = e.target.value;
    setFormValues(newValues);
  };
  const handleButtonClick = async () => {
    const response = await loginMutation({
      variables: {
        email: formValues.email,
        password: formValues.password,
      },
    });
    if (get(response, 'data.login.token')) {
      localStorage.setItem('token', get(response, 'data.login.token'));
      apolloClient.resetStore();
      const username = get(response, 'data.login.user.username');
      Router.push('/u/[userId]', `/u/${username}`);
    }
  };
  return (
    <LayoutSimple>
      <div className="h-full flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center max-w-md w-full space-y-8">
          <div className="flex flex-col items-center">
            <Typography variant="h1" as="h1">
              Sign in to your account
            </Typography>
            <div className="mt-2">
              <Typography variant="body1">
                Or{' '}
                <Link
                  href="/"
                  passHref
                  className="font-medium text-primary-600 hover:text-primary-500"
                >
                  click here to create an account
                </Link>
              </Typography>
            </div>
          </div>
          <div className="w-full rounded-md shadow-sm -space-y-px">
            {/* TODO: Update the styling for the inputs to match
              https://tailwindui.com/components/application-ui/forms/sign-in-forms */}
            <Input
              name="email"
              label="Email"
              placeholder="Email"
              type="email"
              showPlaceholder={false}
              isRounded={false}
              value={formValues.email}
              onChange={handleChange}
            />
            <Input
              name="password"
              label="Password"
              placeholder="Password"
              type="password"
              showPlaceholder={false}
              isRounded={false}
              value={formValues.password}
              onChange={handleChange}
            />
          </div>
          <Button
            colorScheme="primary"
            size="md"
            className="w-full"
            onClick={handleButtonClick}
          >
            Sign in
          </Button>
        </div>
      </div>
    </LayoutSimple>
  );
};

export default withUser({ requireUser: false })(Login);
