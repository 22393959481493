import React, { FC } from 'react';
import clsx from 'clsx';

interface Props {
  name: string;
  placeholder: string;
  label: string;
  type?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  showPlaceholder?: boolean;
  isRounded?: boolean;
}

export const Input: FC<Props> = ({
  name,
  placeholder,
  label,
  type = 'text',
  onChange,
  value,
  showPlaceholder = true,
  isRounded = true,
}) => {
  return (
    <div>
      <label
        htmlFor={name}
        className={clsx('block text-sm font-medium text-gray-700', {
          'sr-only': !showPlaceholder,
        })}
      >
        {label}
      </label>
      <div
        className={clsx({
          'mt-1': showPlaceholder,
        })}
      >
        <input
          type={type}
          name={name}
          id={name}
          value={value}
          className={clsx(
            'shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300',
            {
              'rounded-md': isRounded,
            },
          )}
          placeholder={placeholder}
          onChange={e => onChange(e)}
        />
      </div>
    </div>
  );
};
